import { Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import { CopyHelper } from "./Copy";
import { coinbaseWalletConnection, injectedConnection } from "connection";
import { getConnection } from "connection/utils";
import React, { useCallback, useContext, useEffect } from "react";
import { ExternalLink as LinkIcon } from "react-feather";
import { useAppDispatch } from "state/hooks";
import { updateSelectedWallet } from "state/user/reducer";
import styled, { ThemeContext } from "styled-components/macro";
import { isMobile } from "utils/userAgent";

import { ReactComponent as Close } from "../../assets/images/x.svg";
import { SUPPORTED_WALLETS } from "../../constants/wallet";
import { clearAllTransactions } from "../../state/transactions/reducer";
import { ExternalLink, LinkStyledButton, ThemedText } from "../../theme";
import { shortenAddress } from "../../utils";
import { ExplorerDataType, getExplorerLink } from "utils/getExplorerLink";
import { ButtonSecondary } from "../Button";
import StatusIcon from "../Identicon/StatusIcon";
import { AutoRow } from "../Row";
import Transaction from "./Transaction";
import {
  updatetokenTitle,
  updatetokenSymbol,
  updateSupply,
  updateHolder,
  updateStepNumber,
  updatedeployedTokenAddress,
  updateExplain,
  updateIconImgPath,
} from "state/proposal/reducer";

const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  padding: 1rem 1rem;
  font-weight: 500;
  color: ${(props) =>
    props.color === "blue" ? ({ theme }) => theme.primary1 : "inherit"};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`;

const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`;

const InfoCard = styled.div`
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 20px;
  position: relative;
  display: grid;
  grid-row-gap: 12px;
  margin-bottom: 20px;
`;

const AccountGroupingRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};

  div {
    ${({ theme }) => theme.flexRowNoWrap}
    align-items: center;
  }
`;

const AccountSection = styled.div`
  padding: 0rem 1rem;
  ${({ theme }) =>
    theme.mediaWidth.upToMedium`padding: 0rem 1rem 1.5rem 1rem;`};
`;

const YourAccount = styled.div`
  h5 {
    margin: 0 0 1rem 0;
    font-weight: 400;
  }

  h4 {
    margin: 0;
    font-weight: 500;
  }
`;

const LowerSection = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  padding: 1.5rem;
  flex-grow: 1;
  overflow: auto;
  background-color: ${({ theme }) => theme.bg2};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  h5 {
    margin: 0;
    font-weight: 400;
    color: ${({ theme }) => theme.text3};
  }
`;

const AccountControl = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 0;
  width: 100%;

  font-weight: 500;
  font-size: 1.25rem;

  a:hover {
    text-decoration: underline;
  }

  p {
    min-width: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const AddressLink = styled(ExternalLink)<{ hasENS: boolean; isENS: boolean }>`
  font-size: 0.825rem;
  color: ${({ theme }) => theme.text3};
  margin-left: 1rem;
  font-size: 0.825rem;
  display: flex;
  :hover {
    color: ${({ theme }) => theme.text2};
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`;

const WalletName = styled.div`
  width: initial;
  font-size: 0.825rem;
  font-weight: 500;
  color: ${({ theme }) => theme.text3};
`;

const TransactionListWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
`;

const WalletAction = styled(ButtonSecondary)`
  width: fit-content;
  font-weight: 400;
  margin-left: 8px;
  font-size: 0.825rem;
  padding: 4px 6px;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

function renderTransactions(transactions: string[]) {
  return (
    <TransactionListWrapper>
      {transactions.map((hash, i) => {
        return <Transaction key={i} hash={hash} />;
      })}
    </TransactionListWrapper>
  );
}

interface AccountDetailsProps {
  toggleWalletModal: () => void;
  pendingTransactions: string[];
  confirmedTransactions: string[];
  ENSName?: string;
  openOptions: () => void;
}

export default function AccountDetails({
  toggleWalletModal,
  pendingTransactions,
  confirmedTransactions,
  ENSName,
  openOptions,
}: AccountDetailsProps) {
  const { chainId, account, connector } = useWeb3React();
  const connectionType = getConnection(connector).type;

  const theme = useContext(ThemeContext);
  const dispatch = useAppDispatch();

  const isMetaMask = !!window.ethereum?.isMetaMask;
  const isCoinbaseWallet = !!window.ethereum?.isCoinbaseWallet;
  const isInjectedMobileBrowser = (isMetaMask || isCoinbaseWallet) && isMobile;

  function formatConnectorName() {
    const { ethereum } = window;
    const isMetaMask = !!(ethereum && ethereum.isMetaMask);
    const name = Object.keys(SUPPORTED_WALLETS)
      .filter(
        (k) =>
          SUPPORTED_WALLETS[k].connector === connector &&
          (connector !== injectedConnection.connector ||
            isMetaMask === (k === "METAMASK"))
      )
      .map((k) => SUPPORTED_WALLETS[k].name)[0];
    return <WalletName>Connected with {name}</WalletName>;
  }

  const clearAllTransactionsCallback = useCallback(() => {
    if (chainId) dispatch(clearAllTransactions({ chainId }));
  }, [dispatch, chainId]);

  const clearOnDisconnect = () => {
    localStorage.clear()
    localStorage.removeItem('__lmds_web3_token');
    dispatch(updatedeployedTokenAddress(""));
    dispatch(updatetokenTitle(""));
    dispatch(updatetokenSymbol(""));
    dispatch(updateHolder(""));
    dispatch(updateExplain(""));
    dispatch(updateSupply(0));
    dispatch(updateIconImgPath(""));
  };

  const disconnect = async () => {
    if (connector.deactivate) {
      connector.deactivate();

      // Coinbase Wallet SDK does not emit a disconnect event to the provider,
      // which is what web3-react uses to reset state. As a workaround we manually
      // reset state.
      if (connector === coinbaseWalletConnection.connector) {
        connector.resetState();
      }
    } else {
      connector.resetState();
      clearOnDisconnect();
    }
    dispatch(updateSelectedWallet({ wallet: undefined }));
    openOptions();
  };

  return (
    <>
      <UpperSection>
        <CloseIcon onClick={toggleWalletModal}>
          <CloseColor />
        </CloseIcon>
        <HeaderRow>
          <Trans>Account</Trans>
        </HeaderRow>
        <AccountSection>
          <YourAccount>
            <InfoCard>
              <AccountGroupingRow>
                {formatConnectorName()}
                <div>
                  {!isInjectedMobileBrowser && (
                    <>
                      <WalletAction
                        style={{
                          fontSize: ".825rem",
                          fontWeight: 400,
                          marginRight: "8px",
                        }}
                        onClick={disconnect}
                      >
                        <Trans>Disconnect</Trans>
                      </WalletAction>
                      <WalletAction
                        style={{ fontSize: ".825rem", fontWeight: 400 }}
                        onClick={() => {
                          openOptions();
                        }}
                      >
                        <Trans>Change</Trans>
                      </WalletAction>
                    </>
                  )}
                </div>
              </AccountGroupingRow>
              <AccountGroupingRow data-testid="web3-account-identifier-row">
                <AccountControl>
                  <div>
                    <StatusIcon connectionType={connectionType} />
                    <p>
                      {ENSName ? ENSName : account && shortenAddress(account)}
                    </p>
                  </div>
                </AccountControl>
              </AccountGroupingRow>
              <AccountGroupingRow>
                {ENSName ? (
                  <>
                    <AccountControl>
                      <div>
                        {account && (
                          <CopyHelper
                            toCopy={account}
                            iconPosition="left"
                            gap={6}
                            iconSize={16}
                            fontSize={14}
                          >
                            <Trans>Copy Address</Trans>
                          </CopyHelper>
                        )}
                        {chainId && account && (
                          <AddressLink
                            hasENS={!!ENSName}
                            isENS={true}
                            href={getExplorerLink(
                              chainId,
                              ENSName,
                              ExplorerDataType.ADDRESS
                            )}
                          >
                            <LinkIcon size={16} />
                            <span style={{ marginLeft: "4px" }}>
                              <Trans>View on Explorer</Trans>
                            </span>
                          </AddressLink>
                        )}
                      </div>
                    </AccountControl>
                  </>
                ) : (
                  <>
                    <AccountControl>
                      <div>
                        {account && (
                          <CopyHelper
                            toCopy={account}
                            iconPosition="left"
                            gap={6}
                            iconSize={16}
                            fontSize={14}
                          >
                            <Trans>Copy Address</Trans>
                          </CopyHelper>
                        )}
                        {chainId && account && (
                          <AddressLink
                            hasENS={!!ENSName}
                            isENS={false}
                            href={getExplorerLink(
                              chainId,
                              account,
                              ExplorerDataType.ADDRESS
                            )}
                          >
                            <LinkIcon size={16} />
                            <span style={{ marginLeft: "4px" }}>
                              <Trans>View on Explorer</Trans>
                            </span>
                          </AddressLink>
                        )}
                      </div>
                    </AccountControl>
                  </>
                )}
              </AccountGroupingRow>
            </InfoCard>
          </YourAccount>
        </AccountSection>
      </UpperSection>
      {!!pendingTransactions.length || !!confirmedTransactions.length ? (
        <LowerSection>
          <AutoRow mb={"1rem"} style={{ justifyContent: "space-between" }}>
            <ThemedText.Body>
              <Trans>Recent Transactions</Trans>
            </ThemedText.Body>
            <LinkStyledButton onClick={clearAllTransactionsCallback}>
              <Trans>(clear all)</Trans>
            </LinkStyledButton>
          </AutoRow>
          {renderTransactions(pendingTransactions)}
          {renderTransactions(confirmedTransactions)}
        </LowerSection>
      ) : (
        <LowerSection>
          <ThemedText.Body color={theme.text1}>
            <Trans>Your transactions will appear here...</Trans>
          </ThemedText.Body>
        </LowerSection>
      )}
    </>
  );
}
