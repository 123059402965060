import { get as _get, find as _find } from 'lodash';
import { Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import { Connector } from "@web3-react/types";
import { AutoColumn } from "components/Column";
import { AutoRow } from "components/Row";
import { ConnectionType, injectedConnection } from "connection";
import { getConnection } from "connection/utils";
import React, { useCallback, useEffect, useState } from "react";
import { ArrowLeft } from "react-feather";
import { updateConnectionError } from "state/connection/reducer";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { updateSelectedWallet } from "state/user/reducer";
import styled from "styled-components/macro";

import MetamaskIcon from "../../assets/images/metamask.png";
import { ReactComponent as Close } from "../../assets/images/x.svg";
import { SUPPORTED_WALLETS } from "constants/wallet";
import { useModalIsOpen, useToggleWalletModal } from "state/application/hooks";
import { ApplicationModal } from "state/application/reducer";
import { ExternalLink, ThemedText } from "theme";
import { isMobile } from "utils/userAgent";
import AccountDetails from "../AccountDetails";
import { LightCard } from "../Card";
import Modal from "../Modal";
import Option from "./Option";
import PendingView from "./PendingView";
import { useNavigate } from "react-router-dom";
import axiosHttp from '../../api';

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`;

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
`;

const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  padding: 1rem 1rem;
  font-weight: 500;
  color: ${(props) =>
    props.color === "blue" ? ({ theme }) => theme.primary1 : "inherit"};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`;

const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.bg0};
  padding: 0 1rem 1rem 1rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  ${({ theme }) => theme.mediaWidth.upToMedium`padding: 0 1rem 1rem 1rem`};
`;

const UpperSection = styled.div`
  position: relative;
  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }
  h5:last-child {
    margin-bottom: 0px;
  }
  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`;

const OptionGrid = styled.div`
  display: grid;
  grid-gap: 10px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    grid-gap: 10px;
  `};
`;

const HoverText = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const WALLET_VIEWS = {
  OPTIONS: "options",
  ACCOUNT: "account",
  PENDING: "pending",
};

export default function WalletModal({
  pendingTransactions,
  confirmedTransactions,
  ENSName,
}: {
  pendingTransactions: string[]; // hashes of pending
  confirmedTransactions: string[]; // hashes of confirmed
  ENSName?: string;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { connector, account } = useWeb3React();

  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT);

  const [pendingConnector, setPendingConnector] = useState<
    Connector | undefined
  >();
  const pendingError = useAppSelector((state) =>
    pendingConnector
      ? state.connection.errorByConnectionType[
          getConnection(pendingConnector).type
        ]
      : undefined
  );

  const walletModalOpen = useModalIsOpen(ApplicationModal.WALLET);
  const toggleWalletModal = useToggleWalletModal();

  const openOptions = useCallback(() => {
    setWalletView(WALLET_VIEWS.OPTIONS);
  }, [setWalletView]);

  useEffect(() => {
    if (walletModalOpen) {
      setWalletView(account ? WALLET_VIEWS.ACCOUNT : WALLET_VIEWS.OPTIONS);
    } else if (!walletModalOpen && walletView === "options") {
      navigate("/");
    }
  }, [walletModalOpen, setWalletView, account, walletView, navigate]);

  useEffect(() => {
    if (pendingConnector && walletView !== WALLET_VIEWS.PENDING) {
      updateConnectionError({
        connectionType: getConnection(pendingConnector).type,
        error: undefined,
      });
      setPendingConnector(undefined);
    }
  }, [pendingConnector, walletView]);

  const navigateTo = () => {
        const activeDao = sessionStorage.getItem('__lmds_active_dao')
        if(activeDao)
            return `/${activeDao}`
        return '/'
  }

  const tryActivation = useCallback(
    async (connector: Connector) => {
      const connectionType = getConnection(connector).type;
      try {
        // Fortmatic opens it's own modal on activation to log in. This modal has a tabIndex
        // collision into the WalletModal, so we special case by closing the modal.
        if (connectionType === ConnectionType.FORTMATIC) {
          toggleWalletModal();
        }

        setPendingConnector(connector);
        setWalletView(WALLET_VIEWS.PENDING);
        dispatch(updateConnectionError({ connectionType, error: undefined }));

        await connector.activate();
        dispatch(updateSelectedWallet({ wallet: connectionType }));
        navigate(navigateTo())
      } catch (error: any) {
        console.debug(`web3-react connection error: ${error}`);
        dispatch(
          updateConnectionError({ connectionType, error: error.message })
        );
      }
    },
    [dispatch, navigate, toggleWalletModal]
  );

  // get wallets user can switch too, depending on device/browser
  function getOptions() {
    const isMetaMask = !!window.ethereum?.isMetaMask;
    const isCoinbaseWallet = !!window.ethereum?.isCoinbaseWallet;
    return Object.keys(SUPPORTED_WALLETS).map((key) => {
      const option = SUPPORTED_WALLETS[key];

      const optionProps = {
        isActive: option.connector === connector,
        id: `connect-${key}`,
        link: option.href,
        header: option.name,
        color: option.color,
        key,
        icon: option.iconURL,
      };

      // check for mobile options
      if (isMobile) {
        if (
          (!window.web3 && !window.ethereum && option.mobile) ||
          (isMetaMask && option.name === "MetaMask") ||
          (isCoinbaseWallet && option.name === "Coinbase Wallet")
        ) {
          return (
            <Option
              {...optionProps}
              onClick={() => {
                if (!option.href && !!option.connector) {
                  tryActivation(option.connector);
                }
              }}
              subheader={null}
            />
          );
        }
        return null;
      }

      // overwrite injected when needed
      if (option.connector === injectedConnection.connector) {
        // don't show injected if there's no injected provider
        if (!(window.web3 || window.ethereum)) {
          if (option.name === "MetaMask") {
            return (
              <Option
                id={`connect-${key}`}
                key={key}
                color={"#E8831D"}
                header={<Trans>Install MetaMask</Trans>}
                subheader={null}
                link={"https://metamask.io/"}
                icon={MetamaskIcon}
              />
            );
          } else {
            return null; //dont want to return install twice
          }
        }
        // don't return metamask if injected provider isn't metamask
        else if (option.name === "MetaMask" && !isMetaMask) {
          return null;
        }
        // likewise for generic
        else if (option.name === "Injected" && isMetaMask) {
          return null;
        }
      }

      // return rest of options
      return (
        !isMobile &&
        !option.mobileOnly && (
          <Option
            {...optionProps}
            onClick={() => {
              option.connector === connector
                ? setWalletView(WALLET_VIEWS.ACCOUNT)
                : !option.href &&
                  option.connector &&
                  tryActivation(option.connector);
            }}
            subheader={null} //use option.descriptio to bring back multi-line
          />
        )
      );
    });
  }

  function getModalContent() {
    if (walletView === WALLET_VIEWS.ACCOUNT) {
      return (
        <AccountDetails
          toggleWalletModal={toggleWalletModal}
          pendingTransactions={pendingTransactions}
          confirmedTransactions={confirmedTransactions}
          ENSName={ENSName}
          openOptions={openOptions}
        />
      );
    }

    let headerRow;
    if (walletView === WALLET_VIEWS.PENDING) {
      headerRow = null;
    } else if (walletView === WALLET_VIEWS.ACCOUNT || !!account) {
      headerRow = (
        <HeaderRow color="blue">
          <HoverText
            onClick={() =>
              setWalletView(
                account ? WALLET_VIEWS.ACCOUNT : WALLET_VIEWS.OPTIONS
              )
            }
          >
            <ArrowLeft />
          </HoverText>
        </HeaderRow>
      );
    } else {
      headerRow = (
        <HeaderRow>
          <HoverText>
            <Trans>Connect a wallet</Trans>
          </HoverText>
        </HeaderRow>
      );
    }

    return (
      <UpperSection>
        <CloseIcon onClick={toggleWalletModal}>
          <CloseColor />
        </CloseIcon>
        {headerRow}
        <ContentWrapper>
          <AutoColumn gap="16px">
            {walletView === WALLET_VIEWS.PENDING && pendingConnector && (
              <PendingView
                openOptions={openOptions}
                connector={pendingConnector}
                error={!!pendingError}
                tryActivation={tryActivation}
              />
            )}
            {walletView !== WALLET_VIEWS.PENDING && (
              <OptionGrid data-testid="option-grid">{getOptions()}</OptionGrid>
            )}
            {!pendingError && (
              <LightCard>
                <AutoRow style={{ flexWrap: "nowrap" }}>
                  <ThemedText.Body fontSize={12}>
                    <span>
                      By connecting a wallet, you agree to Uniswap Labs’{" "}
                      <ExternalLink
                        style={{ textDecoration: "underline" }}
                        href="https://uniswap.org/terms-of-service/"
                      >
                        Terms of Service
                      </ExternalLink>{" "}
                      and acknowledge that you have read and understand the
                      Uniswap{" "}
                      <ExternalLink
                        style={{ textDecoration: "underline" }}
                        href="https://uniswap.org/disclaimer/"
                      >
                        Protocol Disclaimer
                      </ExternalLink>
                      .
                    </span>
                  </ThemedText.Body>
                </AutoRow>
              </LightCard>
            )}
          </AutoColumn>
        </ContentWrapper>
      </UpperSection>
    );
  }

  return (
    <Modal
      isOpen={walletModalOpen}
      onDismiss={toggleWalletModal}
      minHeight={false}
      maxHeight={90}
    >
      <Wrapper>{getModalContent()}</Wrapper>
    </Modal>
  );
}
